<template>
  <div class="view-forget-the-password">
    <div class="forget-middle">
      <el-form :model="forgetThePasswordData" :rules="rules" ref="forgetThePasswordData" label-width="110px"
        class="demo-ruleForm">
        <el-form-item label="邮箱" prop="email" :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
          <el-input v-model="forgetThePasswordData.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="邮箱验证码" prop="emailCode">
          <el-input v-model="forgetThePasswordData.emailCode" style="width:500px" placeholder="请输入邮箱验证码"></el-input>
          <button class="getCode" @click.prevent="getCode()">{{yzmtext}}</button>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="forgetThePasswordData.password" type="password" autocomplete="off"
            placeholder="密码要求为8-16位数字、英文字母、特殊符号中至少两种类型以上的组合">
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="ralPassword">
          <el-input v-model="forgetThePasswordData.ralPassword" type="password" autocomplete="off"
            placeholder="请保持与上次密码输入一致"></el-input>
        </el-form-item>
      </el-form>
      <div class="button-okz">
        <button class="go-one" @click="submit('forgetThePasswordData')">保存</button>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <div class="el-log">
        <img src="../assets/images/tongguo.png" alt="">
        <span class="green">修改成功</span>
        <span class="g-to">请您用新的密码重新登录~</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { forgetPassword, sendEmailCode } from '../api/index'
export default {
  name: 'forgetThePassword',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.forgetThePasswordData.ralPassword !== '') {
          this.$refs.forgetThePasswordData.validateField('ralPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.forgetThePasswordData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      forgetThePasswordData: {
        email: '',
        emailCode: '',
        ralPassword: '',
        password: ''
      },
      btnBool: false,
      dialogVisible: false,
      yzmtext: '获取验证码',
      rules: {
        emailCode: [
          { required: true, message: '请输入邮箱验证码', trigger: 'change' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'change' },
          { min: 8, max: 16, message: '密码长度不正确', trigger: 'change' }
        ],
        ralPassword: [
          { required: true, validator: validatePass2, trigger: 'change' },
          { min: 8, max: 16, message: '密码长度不正确', trigger: 'change' }

        ]
      }
    }
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          var testPassword = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
          if (!testPassword.test(this.forgetThePasswordData.ralPassword)) {
            this.$message.error('密码格式有误，请重新输入')
            return
          }
          const res = await forgetPassword(this.forgetThePasswordData)
          if (res.resultCode === 0) {
            this.dialogVisible = true
            var timers = setTimeout(() => {
              this.$router.push({
                path: '/homemain',
                name: 'Homemain',
                query: {
                  forget: 1
                }
              })
              this.dialogVisible = false
              clearInterval(timers)
            }, 500)
          } else {
            this.$message.error(res.resultMsg)
          }
        } else {
          return false
        }
      })
    },
    // 点击获取验证码封装
    onCode () {
      this.btnBool = true
      let i = 60
      var timer = setInterval(() => {
        this.yzmtext = '' + i + 's'
        i--
        if (i < 0) {
          this.btnBool = false
          this.yzmtext = '获取验证码'
          clearInterval(timer)
        }
      }, 1000)
    },
    // 获取验证码
    async getCode () {
      if (this.forgetThePasswordData.email === '') {
        this.$message.error('请输入邮箱')
        return
      }
      const res = await sendEmailCode({
        email: this.forgetThePasswordData.email
      })
      if (res.resultCode === 0) {
        this.onCode()
        this.$message.success('发送成功')
      } else {
        this.$message.error(res.resultMsg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.view-forget-the-password {
  background: #f9f9f9;
  padding: 60px 90px;
  .forget-middle {
    background: #fff;
    padding: 30px 500px;
    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
    }
    /deep/ .el-form-item__content {
      display: flex;
    }
    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;
      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        opacity: 0.7;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        // text-align: center;
        line-height: 44px;
        border: none;
        margin-top: 20px;
      }
    }
    .getCode {
      width: 150px;
      background: #cda156;
      color: #fff;
      text-align: center;
      border: none;
    }
  }
  .el-log {
    display: flex;
    flex-direction: column;
    align-items: center;
    .green {
      font-size: 24px;
      font-weight: 600;
      color: #52d31d;
      padding: 10px 0;
    }
    .g-to {
      font-size: 16px;
      font-weight: 400;
      color: #777;
    }
  }
}
</style>
